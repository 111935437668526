<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Explosive Safety Standards" size="large" />
    <div class="flex flex-wrap" style="max-width: 1150px;">
      <div class="w-full mb-6 ">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph>
            <p>
              Recognized and Generally Accepted Good Engineering Practices, or
              RAGAGEP, is a concept introduced by OSHA in the PSM standard OSHA
              1910.119. It encompasses a broad array of standards, codes, and
              practices across many different industries and governing agencies.
              Applying RAGAGEP to your process is a responsibility placed on
              plant managers, process owners, design engineers, and operators.
              Each person involved has a duty to maintain processes and
              equipment in a way that is based on technically sound and proven
              methods.
            </p>
            <p>
              Depending on your process, the hazards involved, materials, and
              other considerations, applicable RAGAGEP can come from many
              different sources: National Fire Protection Agency (NFPA)
              documents, American Society of Mechanical Engineers (ASME)
              standards, American Petroleum Industry (API) standards, American
              National Standards Institute (ANSI), building and fire codes,
              military safety standards, and other publications. The many
              documents, standards, and codes provide valuable information for
              keeping people, processes, and the public safe.
            </p>
            <p>
              Safety Management Services, Inc. can perform a RAGAGEP analysis on
              your process or plant to determine which regulations or practices
              may be applicable to you and your equipment. If deficiencies are
              found, recommendations are made to ensure the proper RAGAGEP are
              followed and safeguards put into place.
            </p>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Explosive Safety Standards",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Recognized and Generally Accepted Good Engineering Practices (RAGAGEP) was introduced in OSHA 20 1910.119. These practices can come from many sources. SMS can perform RAGAGEP analysis to identify deficiencies in processes and ensure the proper RAGAGEP are followed."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
